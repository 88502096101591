import { ToastContainer } from 'react-toastify';
import { Footer } from '../footer';
import { Header } from '../header';

type Props = { children: React.ReactNode };

export const Wrapper = ({ children }: Props) => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <main>{children}</main>
        <ToastContainer autoClose={5000} />
        <Footer />
      </div>
    </>
  );
};
