// const BASE_ENDPOINT = 'https://developer.host:8443';
// const BASE_ENDPOINT = 'https://logika.108bit.ru:8000';
// const BASE_ENDPOINT = 'https://127.0.0.1:8000';
const BASE_ENDPOINT = 'https://logika.108bit.ru:8000';
// const BASE_ENDPOINT = 'http://127.0.0.1:8443';

const Endpoints = {
  BASE_URL: BASE_ENDPOINT,
  AUTH: {
    join: `${BASE_ENDPOINT}/auth/join-project`,
    isAlreadyJoined: `${BASE_ENDPOINT}/auth/is-already-joined`,
    verification_code: `${BASE_ENDPOINT}/auth/get-verify-code-user-email`,
    upload_file: `${BASE_ENDPOINT}/upload-file`,
    login: `${BASE_ENDPOINT}/auth/jwt/login`,
    logout: `${BASE_ENDPOINT}/auth/jwt/logout`,
    verify: `${BASE_ENDPOINT}/auth/verify`,
    verify_code: `${BASE_ENDPOINT}/auth/send_verify_code`,
    request_verify_token: `${BASE_ENDPOINT}/auth/request-verify-token`,
    registration: `${BASE_ENDPOINT}/auth/registration`,
    // refrech: `${BASE_ENDPOINT}/auth/refrech`,
    // prifile: `${BASE_ENDPOINT}/auth/user_profile`,
    // admin_prifile: `${BASE_ENDPOINT}/auth/admin_profile`,
  },
  PUBLIC: {
    convert_log: `${BASE_ENDPOINT}/upload-log-converter-file`,
  },
  USER: {
    user_me: `${BASE_ENDPOINT}/users/me`,
    user_id: `${BASE_ENDPOINT}/users/{id}`,
    profile: `${BASE_ENDPOINT}profile/{user_id}`,
  },
};

export default Endpoints;
