import { ReactElement } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

export const Header = (): ReactElement => {
  return (
    <>
      <header>
        {/* <Navbar className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="https://108bit.ru/">
          <img alt="" src={logo} width="95" height="auto" className="d-inline-block align-top" />
        </Navbar.Brand>
      </Container>
    </Navbar> */}
        <Navbar data-bs-theme="light" className="shadow">
          <Container fluid>
            {/* <Navbar.Brand className="p-2" href="#home">
          ЛОГика
        </Navbar.Brand> */}
            <Navbar.Brand target="_blank" href="https://108bit.ru/">
              <img alt="" src={logo} width="111" height="auto" className="d-inline-block align-top" />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              {/* <Navbar.Text> */}
              {/* Signed in as: <a href="#login">Mark Otto</a> */}
              {/* <Link to={'/login'}>Войти в систему</Link> */}
              {/* </Navbar.Text> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};
