import axiosInstance from '../api/axiosInstance';
import Endpoints from '../api/endpoints';
import {
  UserRegistrationResponse,
  UserVerificationEmailData,
  UserAlreadyJoinedResponse,
  UserAccessToken,
} from '../models/user';
import axios, { AxiosError } from 'axios';

export const loginAPI = async (email: string, password: string) => {
  try {
    const { data, status, statusText } = await axiosInstance.post<UserAccessToken>(
      Endpoints.AUTH.login,
      { username: email, password: password },
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    );
    console.log(data);
    return data;
  } catch (error) {
    console.log(`Failed to login user: ${email}`);
  }
};

export const sendVerificationCodeAPI = async (email: string): Promise<boolean> => {
  try {
    const { status } = await axiosInstance.post(
      Endpoints.AUTH.join,
      { email: email },
      { headers: { 'Content-Type': 'application/json' } }
    );

    if (status === 200) {
      return true;
    } else {
      console.log('Invalid response status received');
      return false;
    }
  } catch (e) {
    console.log('Def Send Verification Code is error');
    console.log(e);
    return false;
  }
};

export const getVerifyEmailDataAPI = async (email: string): Promise<UserVerificationEmailData> => {
  try {
    const { status, data } = await axiosInstance.post<UserVerificationEmailData>(
      Endpoints.AUTH.verification_code,
      { email: email },
      { headers: { 'Content-Type': 'application/json' } }
    );
    if (status === 200) {
      return data;
    } else console.log('Invalid response status received');
    return data;
  } catch (e) {
    console.log('Def Get Verify Email Data is error');
    console.log(e);
    const verificationData: UserVerificationEmailData = {
      status: false,
      email: null,
      expiration_timestamp: null,
    };
    return verificationData;
  }
};

export const isAlreadyJoinedAPI = async (email: string): Promise<UserAlreadyJoinedResponse | null> => {
  try {
    const { status, data } = await axiosInstance.post(
      Endpoints.AUTH.isAlreadyJoined,
      { email: email },
      { headers: { 'Content-Type': 'application/json' } }
    );
    if (status == 200 && data) {
      console.log(data);
      return {
        status: true,
        message: 'Электронный адрес уже зарегистрирован в системе',
        isError: false,
      };
    }

    if (status == 200 && !data) {
      return {
        status: false,
        message: '',
        isError: false,
      };
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const registerAPI = async (
  email: string,
  timestamp: number,
  verifyCode: string
): Promise<UserRegistrationResponse> => {
  try {
    const { status } = await axiosInstance.post(
      Endpoints.AUTH.registration,
      { email: email, timestamp: timestamp, tg_id: 0, verify_code: verifyCode },
      { headers: { 'Content-Type': 'application/json' } }
    );

    return {
      status: status,
      message: 'user registration is successful',
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400)
        return {
          status: 400,
          message: 'Код для верификации не найден в системе',
        };
      if (error.response?.status === 409)
        return {
          status: 409,
          message: `Пользователь ${email} уже зарегистрирован в системе`,
        };
      if (error.response?.status === 422)
        return {
          status: 409,
          message: `Некорректно указан код верификации`,
        };
      console.log('Def registerAPI is error');
    }
    return {
      status: 500,
      message: `Ошибка на сервере попробуйте позже`,
    };
  }
};
