import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import style from './footer.module.css';

type Props = {};

export const Footer = (props: Props): JSX.Element => {
  return (
    <footer className={style.footer}>
      {/* <div className={style.main_footer}>
        <Container>
          <Row></Row>
        </Container>
      </div> */}
      <Container fluid>
        <Link target="_blank" to={'https://vk.com/ru108bit'}>
          ВКонтакте
        </Link>
        <span>&nbsp;&nbsp;</span>
        <Link target="_blank" to={'https://t.me/ru108bit'}>
          Телеграм
        </Link>
        <span>&nbsp;&nbsp;</span>
        <Link target="_blank" to={'https://108bit.ru/'}>
          108bit.ru
        </Link>
      </Container>
    </footer>
  );
};
