import React from 'react';
import { createContext, useEffect, useState } from 'react';
import {
  UserAccessToken,
  UserAlreadyJoinedResponse,
  UserProfile,
  UserRegistrationResponse,
  UserVerificationEmailData,
} from '../models/user';
import {
  getVerifyEmailDataAPI,
  isAlreadyJoinedAPI,
  loginAPI,
  registerAPI,
  sendVerificationCodeAPI,
} from '../services/authService';
import { boolean } from 'yup';

type Props = { children: React.ReactNode };

type UserContextType = {
  // loginUser: (email: string, password: string) => void;
  // logout: () => void;
  // getUserProfile: () => UserProfile;
  // isVerified: () => boolean;
  userProfile: UserProfile;
  registerUser: (email: string, timestamp: number, verifyCode: string) => Promise<UserRegistrationResponse>;
  registrationVerifyCode: () => Promise<UserVerificationEmailData>;
  joinProject: (email: string) => void;
  isAlreadyJoined: (email: string) => Promise<UserAlreadyJoinedResponse>;
  isLoggedIn: () => boolean;
  loginUser: (email: string, password: string) => Promise<boolean>;
};

const AuthContext = createContext<UserContextType>({} as UserContextType);

export const AuthProvider = ({ children }: Props) => {
  const [userProfile, setUserProfile] = useState<UserProfile>({ email: 'None', verificationStage: false });
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const userProfileLocalStorage = localStorage.getItem('userProfile');

    if (userProfileLocalStorage) {
      setUserProfile(JSON.parse(userProfileLocalStorage));
    }

    setIsReady(true);
  }, []);

  const registerUser = async (
    email: string,
    timestamp: number,
    verifyCode: string
  ): Promise<UserRegistrationResponse> => {
    return await registerAPI(email, timestamp, verifyCode);
  };

  const loginUser = async (email: string, password: string): Promise<boolean> => {
    const data: UserAccessToken | undefined = await loginAPI(email, password);
    if (data != undefined) {
      console.log(data);
      return true;
    } else return false;
  };

  const isLoggedIn = () => {
    return false;
  };

  const isAlreadyJoined = async (email: string): Promise<UserAlreadyJoinedResponse> => {
    let serverAnswer: UserAlreadyJoinedResponse = {
      status: false,
      message: 'Сервер не смог вернуть ответ\nПопробуйте позже',
      isError: true,
    };

    await isAlreadyJoinedAPI(email).then((response: UserAlreadyJoinedResponse | null) => {
      if (response != null) serverAnswer = response;
    });

    return serverAnswer;
  };

  const joinProject = (email: string): void => {
    const user = {
      email: email,
      verificationStage: true,
    };
    localStorage.setItem('userProfile', JSON.stringify(user));
  };

  const registrationVerifyCode = async (): Promise<UserVerificationEmailData> => {
    let verificationData: UserVerificationEmailData | null = null;

    if (userProfile.email != null) {
      await sendVerificationCodeAPI(userProfile.email).then(async (status) => {
        if (status) {
          await getVerifyEmailDataAPI(userProfile.email).then(async (data: UserVerificationEmailData) => {
            verificationData = data;
          });
        }
      });
    } else console.log('User profile is nullable');

    if (verificationData === null) throw 'Failed to obtain verification code information';
    else return verificationData;
  };

  return (
    <AuthContext.Provider
      value={{ joinProject, isLoggedIn, userProfile, registrationVerifyCode, registerUser, isAlreadyJoined, loginUser }}
    >
      {isReady ? children : null}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
