import React from 'react';
import './App.css';
import UploadFileForm from './components/uploadFileForm';
import { ToastContainer } from 'react-toastify';
import Image from 'react-bootstrap/Image';
import logo from './assets/logo.png';
import { Link, Route, Routes } from 'react-router-dom';
import { JoinForm } from './components/auth/joinForm';
import ProtectedRoute from './routes/PrivateRouter';
import { Wrapper } from './components/wrapper';
import RoutesComponents from './routes/RoutesComponents';
import { LoginForm } from './components/auth/LoginForm';
import { ConfirmationCodeForm } from './components/auth/confirmationCodeForm';
import LogConverterPage from './components/logConverter/LogConverter';

function App() {
  return (
    <>
      <Wrapper>
        <Routes>
          <Route path={RoutesComponents.PUBLIC.home} element={<LogConverterPage />} />
          {/* <Route path={RoutesComponents.AUTH.login} element={<LoginForm />} />
          <Route path={RoutesComponents.AUTH.confirmation_code} element={<ConfirmationCodeForm />} />
          <Route path={RoutesComponents.AUTH.join_project} element={<JoinForm />} />
          <Route path={RoutesComponents.PUBLIC.log_converter} element={<LogConverterPage />} />
          <Route
            path={RoutesComponents.USER_PROFILE.home}
            element={
              <ProtectedRoute>
                <UploadFileForm />
              </ProtectedRoute>
            }
          /> */}
        </Routes>
      </Wrapper>
    </>
  );
}

export default App;
