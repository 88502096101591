import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useState } from 'react';

import {
  toastNotifycationError,
  toastNotifycationInfo,
  toastNotifycationSuccess,
} from '../../services/notifycationService';

import styles from './LogConverter.module.css';
import { convertLog } from '../../services/convertLog';

// import { encode } from 'https://cdn.skypack.dev/windows-1252';

function LogConverterPage() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const selectedTypeLog: string = event.currentTarget.type_log_select.value;

    if (selectedFile) {
      const sizeFile = selectedFile.size / 1024 / 1024;
      if (sizeFile > 10.8) toastNotifycationError('Превышен максимальный размер файла');
      else {
        const formData = new FormData();
        setLoading(true);
        formData.append('file', selectedFile!);

        convertLog(formData, selectedTypeLog)
          .then((data) => {
            const csvData = new Blob(['\ufeff', data.logfile], { type: 'data:text/csv; charset=utf-8,' });
            const csvURL = URL.createObjectURL(csvData);
            const link = document.createElement('a');
            link.href = csvURL;
            link.download = data.filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toastNotifycationSuccess(`Лог-файл ${selectedFile.name} обработан`);
            setLoading(false);
          })
          .catch((error) => {
            toastNotifycationError(`Ошибка при загрузке файла: ${selectedFile.name}`);
            setLoading(false);
          });
      }
    } else toastNotifycationInfo('Выберите файл для загрузки');
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = event.currentTarget.files;
      if (files) setSelectedFile(files![0]);
      else toastNotifycationError('Ошибка при выборе файла');
    } catch (e) {
      console.log('Ошибка при добавлени файла');
      toastNotifycationError('Ошибка при добавлени файла');
      console.log(e);
    }
  };

  return (
    <>
      <Container className={styles.container}>
        <Row className="mt-5 mb-5">
          <h1 className="d-flex justify-content-center">Конвертер лог-файлов</h1>
        </Row>
        <Row className="mb-2">
          <div className={styles.text_block}>
            <p>
              Конвертер преобразовывает текстовые файлы журналов в табличный формат удобный для фильтрации и анализа
              данных.
            </p>
            <p>Поддерживается загрузка файлов размером &lt; 10.8 МБ в форматах txt/log.</p>
          </div>
        </Row>
        <Row>
          <Form onSubmit={onSubmit} className={styles.form}>
            <div className={styles.form_line} />
            <Form.Group controlId="formFile">
              <Form.Control onChange={handleOnChange} type="file" accept=".txt,.log" />
            </Form.Group>
            <Form.Group className={styles.form_select_group}>
              <Form.Label className="d-flex justify-content-start">Выберите систему</Form.Label>
              <Form.Select id="type_log_select" defaultValue="Simplex 4100U/4100ES/TSW">
                <option>Simplex 4100U/4100ES/TSW</option>
              </Form.Select>
            </Form.Group>
            <div className={styles.form_line} />
            <Button variant="outline-dark" size="sm" type="submit" disabled={isLoading}>
              {isLoading ? 'Обработка' : 'Преобразовать'}
            </Button>
          </Form>
        </Row>
      </Container>
    </>
  );
}

export default LogConverterPage;
