import axios from 'axios';
import Endpoints from './endpoints';

const axiosInstance = axios.create({
  baseURL: Endpoints.BASE_URL,
});

axiosInstance.defaults.timeout = 12500;
// axiosInstance.defaults.headers;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';

export default axiosInstance;
